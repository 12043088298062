// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-builder-js": () => import("./../../../src/pages/builder.js" /* webpackChunkName: "component---src-pages-builder-js" */),
  "component---src-pages-cms-contentful-js": () => import("./../../../src/pages/cms/contentful.js" /* webpackChunkName: "component---src-pages-cms-contentful-js" */),
  "component---src-pages-cms-index-js": () => import("./../../../src/pages/cms/index.js" /* webpackChunkName: "component---src-pages-cms-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-front-index-js": () => import("./../../../src/pages/front/index.js" /* webpackChunkName: "component---src-pages-front-index-js" */),
  "component---src-pages-front-next-ecommerce-js": () => import("./../../../src/pages/front/next-ecommerce.js" /* webpackChunkName: "component---src-pages-front-next-ecommerce-js" */),
  "component---src-pages-front-vuestorefront-js": () => import("./../../../src/pages/front/vuestorefront.js" /* webpackChunkName: "component---src-pages-front-vuestorefront-js" */),
  "component---src-pages-global-index-js": () => import("./../../../src/pages/global/index.js" /* webpackChunkName: "component---src-pages-global-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pim-akeneo-js": () => import("./../../../src/pages/pim/akeneo.js" /* webpackChunkName: "component---src-pages-pim-akeneo-js" */),
  "component---src-pages-pim-index-js": () => import("./../../../src/pages/pim/index.js" /* webpackChunkName: "component---src-pages-pim-index-js" */),
  "component---src-pages-search-algolia-js": () => import("./../../../src/pages/search/algolia.js" /* webpackChunkName: "component---src-pages-search-algolia-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-templates-core-js": () => import("./../../../src/templates/core.js" /* webpackChunkName: "component---src-templates-core-js" */)
}

